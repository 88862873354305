table {
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    width: 80px;
    height: 30px;
    text-align: center;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  td.selected {
    background-color: #cce5ff; /* Highlight color */
  }
  
  input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 5px;
    box-sizing: border-box;
    text-align: center;
  }
  
  input:focus {
    outline: none;
  }

  #configurator{
    border: 2ps solid green;
  }
  